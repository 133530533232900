
import { computed, defineComponent, reactive, ref, Ref, watch } from "vue";
import dayjs, { Dayjs } from "dayjs";
import { getCalendarLists } from "@/api/request-modules/iteration";
import { ResponseParams } from "@/types/response";
import useMixin from "@/views/iteration/useMixin";
import { OVERVIEW_CALENDAR_STATUS } from "@/utils/contantOptions";
import useWatchChangeProduct from "@/composables/useWatchChangeProduct";
import { store } from "@/store";

export default defineComponent({
  name: "overviewCalendar",
  setup() {
    const currentDate = ref<Date>(new Date());
    const lists = ref([]);
    const formate = (date: Date): string => {
      return `${new Date(date).getFullYear()}-${String(new Date(date).getMonth() + 1).padStart(2, "0")}`;
    };
    const searchParams = reactive({
      month: formate(currentDate.value as Date),
      name: "",
      stage: -1,
      iteration_id: [],
      product_id: null as number | null
    });
    const calendarWrapper = ref();
    const stageMap: Record<number, Record<string, any>> = {
      0: {
        name: "未开始",
        type: "info",
        color: "#999999"
      },
      1: {
        name: "开发",
        type: "success",
        color: "rgb(73, 181, 19)"
      },
      2: {
        name: "联调",
        type: "success",
        color: "rgb(73, 181, 19)"
      },
      3: {
        name: "测试",
        type: "",
        color: "rgb(31, 159, 133)"
      },
      4: {
        name: "发布",
        type: "warning",
        color: "rgb(230, 162, 60)"
      }
    };
    let currentDay = new Date().toLocaleDateString();
    let currentSelectDate: Ref<string> = ref(currentDay.replace(/\//g, "."));
    const iterateList = computed(() => store.getters.iterateList);
    const { handleGetIterationList, iterateListData } = useMixin();
    handleGetIterationList();
    const iterationLists = ref<Array<unknown>>([]);
    const transformList = (list: Array<Record<string, any>>) => {
      list?.forEach((_) => {
        _.uniqueId = Math.random().toString(36).slice(-8);
        _.stageText = stageMap[_.stage].name;
        _.color = stageMap[_.stage].color;
        _.dataTime = [
          {
            time: "",
            realTime: ""
          },
          {
            time: _.dev_time,
            realTime: _.real_dev_time
          },
          {
            time: _.union_time,
            realTime: _.real_union_time
          },
          {
            time: _.test_time,
            realTime: _.real_test_time
          },
          {
            time: _.release_time,
            realTime: _.real_release_time
          }
        ];
      });
      return list;
    };
    const pickDay = (day: Dayjs, list: Array<Record<string, any>>): void => {
      iterationLists.value = transformList(list);
      const year = day.get("year");
      const month = day.get("month") + 1;
      const date = day.get("date");
      currentSelectDate.value = `${year}.${month}.${date}`;
    };
    const getData = (params: typeof searchParams, cb?: (...args: Array<unknown>) => void) => {
      if (!params.product_id) return;
      getCalendarLists<ResponseParams.ResponseDataSuccess>(params).then((res) => {
        console.log(res.data, "res.data");
        lists.value = res.data;
        cb && cb(res.data);
      });
    };
    getData(searchParams, (data) => {
      (data as Array<Record<string, any>>).forEach((item: Record<string, any>) => {
        const date = item.date.replace(/-/g, "/");
        if (new Date(date).getTime() === new Date(currentDay).getTime()) {
          iterationLists.value = transformList(item.list);
        }
      });
    });

    watch(currentDate, (newVal, oldVal) => {
      const newValMonth = new Date(newVal as Date).getMonth() + 1;
      const oldValMonth = new Date(oldVal as Date).getMonth() + 1;
      if (newValMonth !== oldValMonth) {
        let currentClickTime = new Date(dayjs(newVal as Date).format("YYYY-MM-DD")).getTime();
        (searchParams.month = formate(currentDate.value as Date)),
          getData(searchParams, (data) => {
            (data as Array<Record<string, any>>).forEach((item: Record<string, any>) => {
              if (new Date(item.date).getTime() === currentClickTime) {
                iterationLists.value = transformList(item.list);
                calendarWrapper.value.scrollTop = 0;
              }
            });
          });
      }
    });
    // const { productLists } = replaceProductId();

    const handleConditionSearch = () => {
      getData(searchParams);
      iterationLists.value = [];
    };
    useWatchChangeProduct(handleConditionSearch, (newValue) => {
      searchParams.product_id = newValue as number;
      searchParams.iteration_id = [];
      handleConditionSearch();
    });
    //#endregion
    return {
      iterateList,
      iterateListData,
      OVERVIEW_CALENDAR_STATUS,
      calendarWrapper,
      lists,
      currentDate,
      // closeSide,
      pickDay,
      // isShowSide,
      currentSelectDate,
      iterationLists,
      getData,
      searchParams,
      // productLists,
      handleConditionSearch,
      stageMap
    };
  }
});
